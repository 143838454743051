import React from 'react'

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <h1>Forrester</h1>
      </header>
      <img src={'/forrester_family_crest.png'} alt='forrester_family_crest' />
    </div>
  )
}

export default App
